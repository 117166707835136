import { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { getAuth, createUserWithEmailAndPassword } from 'firebase/auth';

const CreateAccountPage = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [error, setError] = useState('');
    
    const navigate = useNavigate();

    const createAccount = async () => {
        try {
            if(password !== confirmPassword) {
                setError('Password and confirm password do not match');
                return;
            }

            await createUserWithEmailAndPassword(getAuth(), email, password);
            navigate('/pets');
        } catch (e) {
            setError(e.message);
        }
    }

    return (
        <div className="d-flex justify-content-center align-items-center">
            <div className="card" style={{backgroundColor: 'var(--primary-color)'}}>
                <h1 className="card-header">Create Account</h1>
                {error && <p className="error">{error}</p>}
                <div className="card-body">
                    <div className="mb-3">
                        <label htmlFor="email" className="form-label">Email:</label>
                        <input 
                            id="email"
                            className="form-control"
                            placeholder="Your email address"
                            value={email}
                            onChange={e=> setEmail(e.target.value)} 
                        />
                    </div>
                    <div className="mb-3">
                        <label htmlFor="password" className="form-label">Password:</label>
                        <input 
                            id="password"
                            className="form-control"
                            type="password"
                            placeholder="Your password"
                            value={password}
                            onChange={e => setPassword(e.target.value)} 
                        />
                    </div>
                    <div className="mb-3">
                        <label htmlFor="confirmPassword" className="form-label">Confirm Password:</label>
                        <input 
                            id="confirmPassword"
                            className="form-control"
                            type="password"
                            placeholder="Re-enter your password"
                            value={confirmPassword}
                            onChange={e => setConfirmPassword(e.target.value)} 
                        />
                    </div>
                    <div className="d-flex justify-content-center mb-3">
                        <button className="btn btn-primary btn-lg w-100" onClick={createAccount}>Create Account</button>
                    </div>
                    <p>
                        Already Have an Account? 
                        <Link to="/login" className="btn btn-outline-secondary btn-sm mx-3">Log In here!</Link>
                    </p>
                </div>
            </div>
        </div>
    );
}

export default CreateAccountPage;