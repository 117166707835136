import { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { getAuth, confirmPasswordReset } from 'firebase/auth';

const ResetPasswordPage = () => {
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [error, setError] = useState('');

  const navigate = useNavigate();

  const handlePasswordReset = async (event) => {
    event.preventDefault(); // Prevent default form submission

    // Get the reset token from the URL
    const resetToken = new URLSearchParams(window.location.search).get('oobCode');

    try {
      // Check if the new passwords match
      if (password !== confirmPassword) {
        setError('Password and confirm password do not match');
        return;
      }

      // Update the password using the reset token
      await confirmPasswordReset(getAuth(), resetToken, password);

      // Password reset successful!
      window.alert("Password reset successful. Please Sign In.");
      navigate('/login');
    } catch (error) {
      // Handle errors
      console.error("Error resetting password:", error);
      setError(error.message);
    }
  };

  return (
    <>
      <h1>Reset Password</h1>
      {error && <p className="error">{error}</p>}
      <form onSubmit={handlePasswordReset}>
        <input
          type="password"
          placeholder="Your New Password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
        <input
          type="password"
          placeholder="Re-enter Your New Password"
          value={confirmPassword}
          onChange={(e) => setConfirmPassword(e.target.value)}
        />
        <button type="submit">Reset Password</button>
      </form>
      <Link to="/login">Already have an account? Log In here!</Link>
    </>
  );
};

export default ResetPasswordPage;
