//localhost/5000/pets/65d6758af73f10e5f51d1f8a
import { useState, useEffect } from 'react';
import { useParams, useNavigate, Link } from 'react-router-dom';
import axios from 'axios';
import NotFoundPage from './NotFoundPage';
import UseUser from '../hooks/useUser';
import {  Button, Card } from 'react-bootstrap';

const PetPage = () => {
    const [petInfo, setPetInfo] = useState();
    const { id } = useParams();
    // console.log("Id using Params on PetPage:", id);
    // const pet = PetsList.find(pet => pet._id ===_id);
    // const navigate = useNavigate();

    const { user, isLoading } = UseUser();
    const navigate = useNavigate();
    
    useEffect(() => {
        const loadPetInfo = async () => {
            const token = user && await user.getIdToken();
            const headers = token ? { authtoken: token } : {};
            const response = await axios.get(`/api/pets/${id}`, { headers });
            const newPetInfo = response.data;
            if (!newPetInfo) {
                return <NotFoundPage />
            } else {
                setPetInfo(newPetInfo);
            }
        }
        if (!isLoading && id) {
            loadPetInfo();
        }
    }, [user, isLoading, id]);
    
    if (!petInfo) {
        return (
            <>
            <h1>Loading...</h1>
            </>
        )
    }
    // console.log(user);


    const deletePetRecord = async (id) => {
        const confirmed = window.confirm(`Are you sure you want to delete the pet record for ${petInfo.name}?`);
        if (!confirmed) {
            return;
        }
        try {
            const token = user && await user.getIdToken();
            const headers = token ? { authtoken: token } : {};
            await axios.delete(`/api/pets/${id}`, {headers});
            alert("Pet record deleted successfully!");
            navigate('/pets');
        } catch (error) {
            console.error("Failed to delete pet record: ", error);
            alert("Failed to delete pet record");
        }
    }

    return (
        <div className="d-flex justify-content-center">
            <Card style={{backgroundColor: 'var(--primary-color)', maxWidth: '1000px', width: '100%'}}>
                <div className="card-header d-flex justify-content-between align-items-center">
                    <h1>{petInfo.name}</h1>
                    {user && (
                        <div className="d-flex">
                            <Button
                                className='btn btn-link'
                                onClick={() => deletePetRecord(petInfo.id)}
                                style={{ cursor: 'pointer' }}
                            >
                                <img
                                    src='../images/png/041-bin.png'
                                    height='45px'
                                    alt='Delete'
                                />
                            </Button>
                            <Button as={Link} className='btn btn-link' to={`/edit_pet/${petInfo.id}`} >
                                <img src='../images/png/069-pencil.png' height='40px' alt={`Edit ${petInfo.name}`} />
                            </Button>
                        </div>
                    )}
                </div>

                {petInfo.petImage ? (
                    <Card.Img src={`${petInfo.petImage}`} alt={`${petInfo.name}`} />
                ) : (
                    <Card.Img src={`../uploads/015-dog.png`} alt={`${petInfo.name}`} />
                )}
                
                <div className="row">
                    <div className="col-6">
                        <ul>
                            <li>Species: {petInfo.species}</li>
                            <li>Color: {petInfo.color}</li>
                            <li>Breed: {petInfo.breed}</li>
                            <li>Age: {petInfo.ageNum} {petInfo.ageUnit}</li>
                            <li>Sex: {petInfo.sexGen}</li>
                            <li>Altered? {petInfo.sexAlt}</li>
                            <li>Cat Friendly? {petInfo.catFriendly}</li>
                            <li>Dog Friendly? {petInfo.dogFriendly}</li>
                            <li>Kid Friendly? {petInfo.kidFriendly}</li>
                        </ul>
                    </div>
                    <div className="col-6">
                        <ul>
                            <li>Pet Rescue: {petInfo.petRescue}</li>
                            <li>Rescue Email: <a href={`mailto:${petInfo.rescueEmail}`} >{petInfo.rescueEmail}</a></li>
                            <li>Rescue Location: {petInfo.rescueCity}, {petInfo.rescueState}</li>
                            <li>Arrival Date: {petInfo.formattedArrivalDate}</li>
                        </ul>
                    </div>
                </div>
                <div className="d-flex justify-content-center">
                    <Button as={Link} to="/pets" className="btn btn-primary btn-lg mb-5">Return to All Pets</Button>
                </div>
            </Card>
        </div>
    );
}

export default PetPage;