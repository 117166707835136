import React from 'react';

export default function FriendlyFilter({ filters, setFilters }) {
    const handleCheckboxChange = (e) => {
        const { name, checked } = e.target;
        setFilters(prevFilters => ({
            ...prevFilters,
            [name]: checked ? 'Yes' : 'No'
        }));
    };

    return (
        <>
            <label className="h5 d-block mb-1">Good With...</label>
            <label className="d-block ms-5">
                <input
                    className='me-1'
                    type="checkbox"
                    name="catFriendly"
                    checked={filters.catFriendly === 'Yes'}
                    onChange={handleCheckboxChange}
                />
                Cats
            </label>
            <label className="d-block ms-5">
                <input
                    className='me-1'
                    type="checkbox"
                    name="dogFriendly"
                    checked={filters.dogFriendly === 'Yes'}
                    onChange={handleCheckboxChange}
                />
                Dogs
            </label>
            <label className="d-block ms-5">
                <input
                    className='me-1'
                    type="checkbox"
                    name="kidFriendly"
                    checked={filters.kidFriendly === 'Yes'}
                    onChange={handleCheckboxChange}
                />
                Kids
            </label>
        </>
    )
}