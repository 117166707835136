import { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { getAuth, signInWithEmailAndPassword, sendPasswordResetEmail } from 'firebase/auth';

const LoginPage = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');
    
    const navigate = useNavigate();

    const handleForgotPassword = async () => {
        try {
            await sendPasswordResetEmail(getAuth(), email);
            alert("Password reset email sent to email entered");
        } catch (error) {
            console.error("Error sending password reset email:", error);
            alert("Failed to send password reset email.");
        }
    } 
    
    const logIn = async () => {
        try {
            await signInWithEmailAndPassword(getAuth(), email, password);
            navigate('/pets');
        } catch (error) {
            setError(error.message);
        }
    }

    return (
        <div className="d-flex justify-content-center align-items-center">
            <div className="card" style={{backgroundColor: 'var(--primary-color)'}}>
                <h1 className="card-header">Log In</h1>
                {error && <p className="error">{error}</p>}
                <div className="card-body">
                    <div className="mb-3">
                        <label htmlFor="email" className="form-label">Email:</label>
                        <input 
                            id="email"
                            className="form-control"
                            placeholder="Your email address"
                            value={email}
                            onChange={e=> setEmail(e.target.value)} 
                        />
                    </div>
                    <div className="mb-3">
                        <label htmlFor="password" className="form-label">Password:</label>
                        <input 
                            id="password"
                            className="form-control"
                            type="password"
                            placeholder="Your password"
                            value={password}
                            onChange={e => setPassword(e.target.value)} 
                        />
                    </div>
                    <div className="d-flex justify-content-center mb-3">
                        <button className="btn btn-primary btn-lg w-100" onClick={logIn}>Log In</button>
                    </div>
                    <div className="d-flex justify-content-between mb-3">
                        <button className="btn btn-outline-secondary btn-sm mx-3"  onClick={handleForgotPassword}>Forgot Password?</button>
                        <Link to="/create-account" className="btn btn-outline-secondary btn-sm mx-3">Create Account</Link>
                    </div>
                    
                </div>
            </div>
        </div>
    );
}

export default LoginPage;