import { NavLink, useNavigate } from 'react-router-dom';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import { getAuth, signOut } from 'firebase/auth';
import UseUser from '../hooks/useUser';

const NavBar = () => {
    const { user } = UseUser();
    const navigate = useNavigate();
    return (
        <>
            <Navbar collapseOnSelect expand="lg" style={{backgroundColor: "#96b6c5"}}>
                <Container>
                    <Navbar.Brand href="/">
                        <h1>
                            <span id="acronym">ARC</span>
                            <span id="company">Animal Rescue Coordinator</span>
                        </h1>
                    </Navbar.Brand>
                    <Navbar.Toggle aria-controls="basic-navbar-nav" />
                    <Navbar.Collapse id="basic-navbar-nav">
                        <Nav className="me-auto">
                            <Nav><NavLink to="/" className="nav-link bg-tertiary">Home</NavLink></Nav>
                            <Nav><NavLink to="/pets" className="nav-link bg-tertiary">View All Pets</NavLink></Nav>
                            <Nav><NavLink to="/contact" className="nav-link bg-tertiary">Contact Us</NavLink></Nav>
                        </Nav>
                        <Nav className="justify-content-end">
                            {user
                                ? <>
                                    <Nav><NavLink to="/create_pet" className="nav-link bg-tertiary">Add Pet</NavLink></Nav>
                                    <button className="btn btn-primary" onClick={() => {signOut(getAuth());}}>Log Out</button>                                    
                                </>
                                : <button className="btn btn-primary" onClick={() => {navigate("/login");}}>Sign In</button>
                            }
                        </Nav>
                    </Navbar.Collapse>
                </Container>
            </Navbar>
        </>
    )
}

export default NavBar;