import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

export default function Footer() {
    return (
        <div className="container">
            <footer className="text-center text-lg-start" style={{backgroundColor: "#4a86a1"}}>
                <Container className="p-4 pb-0">
                    <section>
                        <Row>
                            <Col className="col-md-4 col-lg-4 col-xl-4 mx-auto mb-2">
                                <a className="btn btn-social mx-2" href="#!"><img src="../images/png/085-facebook.png" height="40" alt="Facebook Icon" /></a>
                                <a className="btn btn-social mx-2" href="#!"><img src="../images/png/086-linkedin.png" height="40" alt="LinkedIn Icon"/></a>
                                <a className="btn btn-social mx-2" href="#!"><img src="../images/png/088-twitter.png" height="40" alt="Twitter Icon"/></a>
                                <a className="btn btn-social mx-2" href="#!"><img src="../images/png/089-youtube.png" height="40" alt="YouTube Icon"/></a>
                            </Col>

                            <hr class="w-100 clearfix d-md-none" />

                            <Col className="col-md-3 col-lg-4 col-xl-4 mx-auto mt-2">
                                <div className="copyright py-2">
                                    <small>Copyright &copy; Lily Ellison 2024</small>
                                </div>
                            </Col>

                            <hr class="w-100 clearfix d-md-none" />

                            <Col className="col-md-4 col-lg-4 col-xl-4 mx-auto mt-2">
                                <p id="legalIcon" className="text-muted">
                                    Icons made by <a href="https://www.flaticon.com/authors/freepik" className="remove-decoration text-muted" title="Freepik">Freepik</a>, <a href="https://www.flaticon.com/authors/kalashnyk" className="remove-decoration text-muted" title="Kalashnyk">Kalashnyk</a>, and <a href="https://www.flaticon.com/authors/flat-icons" className="remove-decoration text-muted" title="flat-icons">Flat Icons</a> from <a href="https://www.flaticon.com/" className="remove-decoration text-muted" title="Flaticon">www.flaticon.com</a>
                                </p>
                            </Col>
                        </Row>
                    </section>
                </Container>
            </footer>
        </div>
    );
}



// import React from "react";
// import Container from 'react-bootstrap/Container';
// import Row from 'react-bootstrap/Row';
// import Col from 'react-bootstrap/Col';


// export default function Footer() {
//     return (
//         <div>
//             <footer className="footer mt-auto py-3">
//                 <Container className="text-muted">
//                     <Row className=" row">
//                         <Col className="mb-2 col-md-4">
//                             <a className="btn btn-social mx-2" href="#!"><img src="../images/png/085-facebook.png" height="40" alt="Facebook Icon" /></a>
//                             <a className="btn btn-social mx-2" href="#!"><img src="../images/png/086-linkedin.png" height="40" alt="LinkedIn Icon"/></a>
//                             <a className="btn btn-social mx-2" href="#!"><img src="../images/png/088-twitter.png" height="40" alt="Twitter Icon"/></a>
//                             <a className="btn btn-social mx-2" href="#!"><img src="../images/png/089-youtube.png" height="40" alt="YouTube Icon"/></a>
//                         </Col>
//                         <Col className="text-center col-md-4">
//                             <div className="copyright py-2">
//                                 <small>Copyright &copy; Lily Ellison 2024</small>
//                             </div>
//                         </Col>
//                         <Col className="text-center col-md-4">
//                             <p id="legalIcon"> Icons made by <a href="https://www.flaticon.com/authors/freepik" className="remove-decoration text-muted" title="Freepik"> Freepik</a>, <a href="https://www.flaticon.com/authors/kalashnyk" className="remove-decoration text-muted" title="Kalashnyk">Kalashnyk </a>, and <a href="https://www.flaticon.com/authors/flat-icons" className="remove-decoration text-muted" title="flat-icons">Flat Icons </a>  from <a href="https://www.flaticon.com/" className="remove-decoration text-muted" title="Flaticon">www.flaticon.com</a></p>
//                         </Col>
//                     </Row>
//                 </Container>
//             </footer>
//         </div>
//     )
// }