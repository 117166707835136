import React, { useEffect, useState, useCallback } from 'react';
import axios from 'axios';
import {GoogleMap, LoadScript, Marker, InfoWindow} from '@react-google-maps/api';

const mapContainerStyle = {
    width: '100vw',
    height: '100vh',
};
const defaultCenter = {
    lat: 22.54992,
    lng: 0,
};

const GoogleMapComponent = () => {
    const [cities, setCities] = useState([]);
    const [selectedCity, setSelectedCity] = useState(null);
    const [map, setMap] = useState(null);

    useEffect(() => {
        const fetchCities = async () => {
            try {
                const response = await axios.get('/api/map');
                setCities(response.data);
            } catch (error) {
                console.error('Error fetching cities: ', error);
            }
        };
        fetchCities();
    }, []);

    const onLoad = useCallback((map) => {
        setMap(map);
    }, []);

    useEffect(() => {
        if (map && cities.length > 0) {
            const bounds = new window.google.maps.LatLngBounds();
            cities.forEach(city => {
                bounds.extend(new window.google.maps.LatLng(city.cityLatLng.lat, city.cityLatLng.lng));
            });
            map.fitBounds(bounds);
        }
    }, [map, cities]);



    return (
        <>
            <LoadScript googleMapsApiKey="AIzaSyA12d5Q2uzAjEy94ObPIDm1Td0cO76bZj4">
                <GoogleMap
                    mapContainerStyle={mapContainerStyle}
                    zoom={3}
                    center={defaultCenter}
                    onLoad={onLoad}
                >
                    {cities.map((city, index) => (
                    <Marker
                        key={index}
                        position={{ lat: city.cityLatLng.lat, lng: city.cityLatLng.lng }}
                        onClick={() => setSelectedCity(city)}
                    />
                    ))}

                    {selectedCity && (
                    <InfoWindow
                        position={{ lat: selectedCity.cityLatLng.lat, lng: selectedCity.cityLatLng.lng }}
                        onCloseClick={() => setSelectedCity(null)}
                    >
                        <div>
                        <h2>{selectedCity._id.city}, {selectedCity._id.state}</h2>
                        <ul>
                            {selectedCity.pets.map((pet, idx) => (
                            <li key={idx}>{pet.name}</li>
                            ))}
                        </ul>
                        </div>
                    </InfoWindow>
                    )}
                </GoogleMap>
            </LoadScript>
        </>
    )
}

export default GoogleMapComponent;