import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { initializeApp } from "firebase/app";
import 'bootstrap/dist/css/bootstrap.min.css';

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyDEpmV47n1qRMB5A61BVHrj5q9kEhRUjH8",
  authDomain: "my-react-site-6f999.firebaseapp.com",
  projectId: "my-react-site-6f999",
  storageBucket: "my-react-site-6f999.appspot.com",
  messagingSenderId: "1001519179303",
  appId: "1:1001519179303:web:8214e3e90f6de2130e1002"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
