import './App.css';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import NavBar from './components/NavBar';
import Footer from './components/Footer';
import HomePage from './pages/HomePage';
import PetPage from './pages/PetPage';
import PetsListPage from './pages/PetsListPage';
import NotFoundPage from './pages/NotFoundPage';
import LoginPage from './pages/LoginPage';
import CreateAccountPage from './pages/CreateAccountPage';
import AddPetPage from './pages/AddPetPage';
import EditPetPage from './pages/EditPetPage';
import ContactPage from './pages/ContactPage';
import ResetPasswordPage from './pages/ResetPasswordPage';
import MapPage from './pages/MapPage';
import 'bootstrap/dist/css/bootstrap.min.css';

function App() {
  document.body.style.backgroundColor = "#F1F0E8";
  return (
    <BrowserRouter>
      <div className="App">
        <NavBar />
        <div id="page-body" style={{margin: 20}} className="content-container">
          <Routes>
            <Route path="/" element={<HomePage />} />
            <Route path="/pets" element={<PetsListPage />} />
            <Route path="/map" element={<MapPage />} />
            <Route path="/pets/:id" element={<PetPage />} />
            <Route path="/contact" element={<ContactPage />} />
            <Route path="/create_pet" element={<AddPetPage />} />
            <Route path="/edit_pet/:id" element={<EditPetPage />} />
            <Route path="/login" element={<LoginPage />} />
            <Route path="/create-account" element={<CreateAccountPage />} />
            <Route path="/manage_account" element={<ResetPasswordPage />} />
            <Route path="*" element={<NotFoundPage />} />
          </Routes>
        </div>
        <Footer />
      </div>
    </BrowserRouter>
  );
}

export default App;
