import React from "react";
// We import bootstrap to make our application look better.
// import "bootstrap/dist/css/bootstrap.css";
// import Button from "react-bootstrap/Button";
import { Link } from 'react-router-dom';
 // Here, we display our Home Page
const HomePage = () => {
    return (

        <div className="container d-flex align-items-center flex-column">
            <h2>Welcome to the Animal Rescue Coordinator!</h2>
            {/* <img src="../images/dog-with-hearts.svg" alt="Dog with Hearts" />
            <a href="https://storyset.com/friends" className="remove-decoration text-muted">Friends illustrations by Storyset</a> */}
            <img src="../images/PNGLogoFiles/OriginalLogo.png" height="200px" alt="ARC Logo"/>
            <section className="page-section spaced">
            <div className="container">
                <h4 className="page-section-heading text-center mb-2">What is the Animal Rescue Coordinator?</h4>
                <p>The Animal Rescue Coordinator (ARC) is an application for foster-based animal rescues and their volunteers. It contains tools to assist with communication, coordination, and organization needs of these unique programs. The current main goal is to provide a list of current pets looking for foster homes and information about the rescues helping them.</p>
            </div>
            </section>
            <section className="page-section spaced">
            <div className="container">
            <h4 className="page-section-heading text-center mb-2">What can ARC do?</h4>
            <img src="../images/adopt-a-pet-animate.svg" alt="Adoption Animation" />
            <a href="https://storyset.com/friends" className="remove-decoration text-muted">Friends illustrations by Storyset</a>
                <p>Spread the word for pets looking for fosters. </p>
                <Link to="/pet_record_list" className="spaced">
                {/* <Button>View All Pets</Button> */}
                </Link>
                <Link to="/signup" className="spaced">
                {/* <Button>Create An Account</Button> */}
                </Link>
                <Link to="/login" className="spaced">
                {/* <Button>Login</Button> */}
                </Link>
                <Link to="/contact" className="spaced">
                {/* <Button>Contact Us</Button> */}
                </Link>

            </div>
            </section>
            
            <section className="page-section spaced">
            <div className="container">
            <h4 className="page-section-heading text-center mb-2">Plans for the Future?</h4>
            <img src="../images/cat-and-dog-animate.svg" alt="Dog by plant" />
            <a href="https://storyset.com/friends" className="remove-decoration text-muted">Friends illustrations by Storyset</a>
                <ul>
                    <li><img src="../images/png/003-location.png" height="40px" alt="pointer" /> Location Services</li>
                    <li><img src="../images/png/096-alarm.png" height="40px" alt="alarm bell" /> Notifications</li>
                    <li><img src="../images/png/059-attached.png" height="40px" alt="attachment" /> Record Storage</li>
                    <li><img src="../images/png/004-chat.png" height="40px" alt="paw bubbles" /> Message Boards</li>
                    <li><img src="../images/png/010-notebook.png" height="40px" alt="paw notebook" /> Wish Lists</li>
                    <li><img src="../images/png/009-balloons.png" height="40px" alt="paw balloons" /> Event Planning and Coordination</li>
                    <li><img src="../images/png/007-pawprint.png" height="40px" alt="paw plus" /> Appointment and Transportation Scheduling</li>
                </ul>
            </div>
            </section>
            <section className="page-section">
            
                </section>
        </div>

    );
}
export default HomePage;
