import React from 'react';
import { Form, Row } from 'react-bootstrap';

export default function AgeFilter({ filters, setFilters, filterOptions }) {
    const handleSelectChange = (e) => {
        const { name, value } = e.target;
        setFilters(prevFilters => ({
            ...prevFilters,
            [name]: value,
        }));
    };

    return (
        <>
            <div className="form-group mb-3 d-flex align-items-center justify-content-between">
                <label className="h5 form-label me-2 mb-0">Age:</label>
                <select
                    className="form-select"
                    style={{ maxWidth: "200px" }}
                    name="age"
                    value={filters.age}
                    onChange={handleSelectChange}
                >
                    <option value="">Any</option>
                    {filterOptions.ageNums.map((age, index) => (
                        <option key={index} value={age}>{age}</option>
                    ))}
                </select>
            </div>

            

        </>
    )
}