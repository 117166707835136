import React from 'react';
import GoogleMapComponent from '../components/GoogleMap';
import { Link } from 'react-router-dom';

const MapPage = () => {

    
    return (
        <>
            <h1>Map View</h1>
            <div className="d-flex justify-content-end">
                <Link to={'/pets'} className='btn mt-2'>
                    <img src="../images/png/103-pet-card.png" height="30px" alt="pet card" /> List View
                </Link>
            </div>
            <GoogleMapComponent />
        </>
    )
}

export default MapPage;