import { Link } from 'react-router-dom';
import { Card, Button } from 'react-bootstrap';

const PetsList = ({ pets }) => {
    
    return (
        <div className="d-flex flex-wrap justify-content-center">
            {pets.map(pet => (
                <Card style={{width: '18rem', borderWidth: '3px', margin: '5px'}}>
                    <Link key={pet._id} to={`/pets/${pet._id}`}>
                        <Card.Img 
                            variant="top" 
                            src={`${pet.petImage}`}
                            alt={`${pet.name}`} 
                            style={{ width: '100%', height: '200px', objectFit: 'cover' }}
                            />
                        <Card.Body>
                            <Card.Title>
                                    <h3>{pet.name}</h3>
                                
                            </Card.Title>
                            <Card.Text>
                                    <ul>
                                        <li>Age: {pet.ageNum} {pet.ageUnit}</li>
                                        <li>Sex: {pet.sexGen}</li>
                                        <li>Altered? {pet.sexAlt}...</li>
                                    </ul>
                                    <Button variant="primary" as='a' href={`/pets/${pet._id}`}>View Details</Button>
                            </Card.Text>
                        </Card.Body>
                    </Link>
                </Card>                
            ))}
        </div>
    )
}

export default PetsList;