import React from 'react';
import { Button, Card, Col, Container, Row } from 'react-bootstrap';

const ContactPage = () => {
    return (
        <Card className='text-center' style={{backgroundColor: 'var(--primary-color)'}}>
            <h1 className="card-header">Contact And Connect</h1>

            <Card.Body>
                <Card.Text>The ARC App and Website were designed, built, and deployed by Lillian (Lily) Ellison, a software developer from an animal care background.</Card.Text>
                <Card className="bg-dark text-white mx-auto mb-3" style={{backgroundColor: 'var(--primary-color)', width: '200px'}}>
                    <Card.Img src="../images/lily.jpg" alt='Lily Ellison' />
                    <Card.ImgOverlay className="d-flex align-items-end p-0">
                        <Card.Title className="bg-dark bg-opacity-50 w-100 text-left m-0 p-2">Lily Ellison</Card.Title>
                    </Card.ImgOverlay>
                </Card>
                <Card.Text>Please use any of the following links to connect with Lily in your preferred method(s):</Card.Text>
                <Container>
                    <Row>
                        <Col xs={12} sm={6} md={4} className="mb-3">
                            <Button variant="dark" className="w-100 h-100" href="mailto:tehlily@gmail.com">
                                <img src="../images/png/email.png" height="40px" alt="Email" /> Email
                            </Button>
                        </Col>
                        <Col xs={12} sm={6} md={4} className="mb-3">
                            <Button variant="dark" className="w-100 h-100" href="http://www.thebeaddragon.com" target="_blank">
                                <img src="../images/png/internet.png" height="40px" alt="website" /> Website
                            </Button>
                        </Col>
                        <Col xs={12} sm={6} md={4} className="mb-3">
                            <Button variant="dark" className="w-100 h-100" href="https://www.linkedin.com/in/lily13ellison/" target="_blank">
                                <img src="../images/png/linkedin.png" height="40px" alt="linkedIn" /> LinkedIn
                            </Button>
                        </Col>
                        <Col xs={12} sm={6} md={4} className="mb-3">
                            <Button variant="dark" className="w-100 h-100" href="https://www.facebook.com/lily.ellison83" target="_blank">
                                <img src="../images/png/facebook.png" height="40px" alt="Facebook" /> Facebook
                            </Button>
                        </Col>
                        <Col xs={12} sm={6} md={4} className="mb-3">
                            <Button variant="dark" className="w-100 h-100" href="https://github.com/TehLily" target="_blank">
                                <img src="../images/png/github.png" height="40px" alt="GitHub" /> GitHub
                            </Button>
                        </Col>
                        <Col xs={12} sm={6} md={4} className="mb-3">
                            <Button variant="dark" className="w-100 h-100" href="https://gitlab.com/tehlily" target="_blank">
                                <img src="../images/png/gitlab.png" height="40px" alt="GitLab" /> GitLab
                            </Button>
                        </Col>
                    </Row>
                </Container>
                <h2>Thank you!</h2>
            </Card.Body>
            
        </Card>
    )
}

export default ContactPage;