import React, { useEffect, useState } from 'react';
import axios from 'axios';
import PetsList from '../components/PetsList';
import { Link } from 'react-router-dom';
import UseUser from '../hooks/useUser';
import MainFilter from '../components/Filter/MainFilter';
import { Button } from 'react-bootstrap';

const PetsListPage = () => {
    const [pets, setPets] = useState([]);
    const { user } = UseUser();
    const initialFilters = { age: '', species: '', rescueCity: '', rescueState: '', catFriendly: '', dogFriendly: '', kidFriendly: '', rescue: '', sex: '', altered: '' };
    const [filters, setFilters] = useState(initialFilters);
    const [filterOptions, setFilterOptions] = useState({ ageNums: [], specieses: [], rescueLocals: [], friendlies: [], petRescues: [], sexes: [], altereds: [] })

    const fetchPets = async () => {
        try {
            const response = await axios.get('api/pets');
            // console.dir(response);
            const petsData = response.data;
            // console.log(`Response data: ${petsData}`);
            // console.log('Formatted Response Data:', JSON.stringify(response.data, null, 2));

            setPets(petsData);
        } catch (error) {
            console.error("Error fetching pets data:", error);
        }
    };
    useEffect(() => {
        fetchPets();
    }, []);

    const addFilters = async () => {
        const hasFilterValues = Object.values(filters).some(value => value !== '');
        if (hasFilterValues) {
            const queryParams = new URLSearchParams(filters);
            try {
                const response = await axios.get(`/api/pets?${queryParams.toString()}`);
                // console.log('Filtered Pets:', response.data);
                setPets(response.data);
            } catch (error) {
                console.error("Error fetching filtered pets:", error);
            }
        } else {
            // console.log("No filter values applied.");
        }
    };

    const removeFilters = () => {
        setFilters(initialFilters);
        fetchPets();
    }

    const handleFilterChange = (newFilters) => {
        setFilters(newFilters);
    }

    useEffect(() => {
        async function fetchFilterOptions() {
            try {
                const filterResponse = await axios.get('api/pet_filters');
                // console.log("Filter Response:", filterResponse.data);
                setFilterOptions(filterResponse.data);

            } catch (error) {
                console.error("Error fetching filter options:", error);
            }
        };
        fetchFilterOptions();
    }, []);

    return (
        <>
            <h1>Pets</h1>
            {user &&
                <Button as={Link} variant="outline-primary" className="btn mb-2" to={`/create_pet`}>
                    Add A Pet
                </Button>
            }
            <div className="d-flex justify-content-between mx-3">
                <MainFilter
                    filters={filters}
                    setFilters={handleFilterChange}
                    filterOptions={filterOptions}
                    addFilters={addFilters}
                    removeFilters={removeFilters}
                />

                <Link to={'/map'} className='btn mt-2'>
                    <img src="../images/png/102-location-pin.png" height="30px" alt="pointer" /> Map View
                </Link>
            </div>

            {pets.length > 0 ? (
                <PetsList pets={pets} />
            ) : (
                <div>No pets found matching the current filters. Please Remove Filters.</div>
            )}
        </>
    );
}

export default PetsListPage;