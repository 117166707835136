import React, { useState} from 'react';
import FriendlyFilter from './Friendly/FriendlyFilter';
import AgeFilter from './Age/AgeFilter';
import { Accordion, Form } from 'react-bootstrap';

export default function MainFilter({ filters, setFilters, filterOptions, addFilters, removeFilters }) {

    const [activeKey, setActiveKey] = useState(null);

    const handleFilterChange = (e) => {
        const { name, value } = e.target;
        if ( name === 'location') {
            const [rescueCity, rescueState] = value.split(', ');
            setFilters(prevFilters => ({
                ...prevFilters, 
                rescueCity,
                rescueState
            }));
        } else {
            setFilters(prevFilters => ({
                ...prevFilters,
                [name]: value,
            }));
        }
    };

    const handleApplyFilters = () => {
        addFilters();
        setActiveKey(null); // Close the accordion
    };

    const handleRemoveFilters = () => {
        removeFilters();
        setActiveKey(null); // Close the accordion
    };


    return (
        <Accordion activeKey={activeKey} onSelect={setActiveKey} style={{ maxWidth: '400px' }}>
            <Accordion.Item eventKey="0">
                <Accordion.Header>Filter By...</Accordion.Header>
                <Accordion.Body>
                    <div>
                        <AgeFilter className="d-flex" filters={filters} setFilters={setFilters} filterOptions={filterOptions}/>
                        
                        <FriendlyFilter filters={filters} setFilters={setFilters} />

                        <div className="form-group mb-3 d-flex align-items-center justify-content-between">
                            <label className="h5 form-label me-2 mb-0">
                                Species:
                            </label>
                                <select 
                                    className="form-select"
                                    style={{ maxWidth: "200px" }}
                                    name="species" 
                                    value={filters.species} 
                                    onChange={handleFilterChange}
                                >
                                    <option value="">Any</option>
                                    {filterOptions.specieses.map((species, index) => (
                                        <option key={index} value={species}>{species}</option>
                                    ))}
                                </select>
                        </div>
                        <div className="form-group mb-3 d-flex align-items-center justify-content-between">
                            <label className="h5 form-label me-2 mb-0">
                                Sex:
                            </label>
                            <select 
                                className="form-select"
                                style={{ maxWidth: "200px" }}
                                name="sex" 
                                value={filters.sex} 
                                onChange={handleFilterChange}
                            >
                                <option value="">Any</option>
                                {filterOptions.sexes.map((sex, index) => (
                                    <option key={index} value={sex}>{sex}</option>
                                ))}
                            </select>
                        </div>

                        <div className="form-group mb-3 d-flex align-items-center justify-content-between">
                            <label className="h5 form-label me-2 mb-0">
                                Altered?
                            </label>
                            <select 
                                className="form-select"
                                style={{ maxWidth: "200px" }}
                                name="altered" 
                                value={filters.altered} 
                                onChange={handleFilterChange}
                            >
                                <option value="">Any</option>
                                {filterOptions.altereds.map((altered, index) => (
                                    <option key={index} value={altered}>{altered}</option>
                                ))}
                            </select>
                        </div>

                        <div className="form-group mb-3 d-flex align-items-center justify-content-between">
                            <label className="h5 form-label me-2 mb-0">
                                Rescue Name:
                            </label>
                            <select 
                                className="form-select"
                                style={{ maxWidth: "200px" }}
                                name="rescue" 
                                value={filters.rescue} 
                                onChange={handleFilterChange}
                            >
                                <option value="">Any</option>
                                {filterOptions.petRescues.map((rescue, index) => (
                                    <option key={index} value={rescue}>{rescue}</option>
                                ))}
                            </select>
                        </div>
                        <div className="form-group mb-3 d-flex align-items-center">
                            <label className="h5 form-label me-2 mb-0">
                                Location:
                            </label>
                            <select 
                                className="form-select"
                                style={{ maxWidth: "200px" }}
                                name="location" 
                                value={filters.location} 
                                onChange={handleFilterChange}
                            >
                                <option value="">Any</option>
                                {filterOptions.rescueLocals.map((location, index) => (
                                    <option key={index} value={`${location.city}, ${location.state}`}>{location.city}, {location.state}</option>
                                ))}
                            </select>
                        </div>
                    </div>
                    <div className="d-flex justify-content-between mb-3">
                        <button className="btn btn-primary mx-2" onClick={handleApplyFilters}>Apply Filters</button>
                        <button className="btn btn-outline-primary btn-sm mx-2" onClick={handleRemoveFilters}>Remove All Filters</button>
                    </div>
                </Accordion.Body>
            </Accordion.Item>
        </Accordion>
    )
}