import React, { useState } from 'react';
import { useNavigate } from "react-router-dom";
import axios from 'axios';
import { Formik, Field, Form } from 'formik';
import * as Yup from 'yup';

const AddPetPage = () => {
    const defaultAnswer = "Unknown";
    const [petImage, setPetImage] = useState(null);
    const states = [
        "Alabama", "Alaska", "Arizona", "Arkansas", "California", "Colorado",
        "Connecticut", "Delaware", "Florida", "Georgia", "Hawaii", "Idaho",
        "Illinois", "Indiana", "Iowa", "Kansas", "Kentucky", "Louisiana",
        "Maine", "Maryland", "Massachusetts", "Michigan", "Minnesota",
        "Mississippi", "Missouri", "Montana", "Nebraska", "Nevada",
        "New Hampshire", "New Jersey", "New Mexico", "New York",
        "North Carolina", "North Dakota", "Ohio", "Oklahoma", "Oregon",
        "Pennsylvania", "Rhode Island", "South Carolina", "South Dakota",
        "Tennessee", "Texas", "Utah", "Vermont", "Virginia", "Washington",
        "West Virginia", "Wisconsin", "Wyoming"
    ]

    const validationSchema = Yup.object({
        name: Yup.string().required("Name is required"),
        petRescue: Yup.string().required("Rescue name is required"),
        rescueEmail: Yup.string().email("Invalid email format").required("Rescue Email is required"),
        rescueCity: Yup.string().required("City is required"),
        rescueState: Yup.string().required("Please select a state").oneOf(states),
        species: Yup.string(),
        breed: Yup.string(),
        color: Yup.string(),
        ageNum: Yup.number().min(1),
        ageUnit: Yup.string(),
        sexGen: Yup.string(),
        sexAlt: Yup.string(),
        dogFriendly: Yup.string(),
        catFriendly: Yup.string(),
        kidFriendly: Yup.string(),
        arrivalDate: Yup.date().required("Please select an arrival date"),
    })

    const initialPetValues = {
        name: "",
        species: "",
        breed: "",
        color: "",
        ageNum: "0",
        ageUnit: "",
        sexGen: defaultAnswer,
        sexAlt: defaultAnswer,
        dogFriendly: defaultAnswer,
        catFriendly: defaultAnswer,
        kidFriendly: defaultAnswer,
        petRescue: "",
        rescueEmail: "",
        rescueCity: "",
        rescueState: "Select State",
        arrivalDate: "",
        petImage: null,
    }

    const navigate = useNavigate();

    const handleFileChange = (e) => {
        const file = e.target.files[0];
        setPetImage(file);
    };
    

    const handleSubmit = async (values, { setSubmitting, setFieldError }) => {
        // console.log("Submitting");
        try {
            const response = await axios.post('/api/validate_location', {
                city: values.rescueCity,
                state: values.rescueState,
            });
            const isValidCity = response.data.valid;
            if (!isValidCity) {
                setFieldError('rescueCity', 'Invalid city');
                setFieldError('rescueState', 'Invalid state');
                setSubmitting(false);
                return;
            }
        
            const formData = new FormData();
            for (const key in values) {
                formData.append(key, values[key]);
            }
            // console.log(formData);
            if (petImage) {
                formData.append('petImage', petImage);
            }
            
            const uploadResponse = await axios.post('/api/pets', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });
            // console.log('Upload response: ', uploadResponse.data);
            navigate('/pets');
        } catch (error) {
            console.error('Error uploading pet data: ', error);
        } finally {
            setSubmitting(false);
        }
    }

    return (
        <>
        <h1>Add A Pet:</h1>
        <div className="container">
        <Formik
            initialValues={initialPetValues}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
        >
            {({ isSubmitting, errors, touched }) => (
                <Form >
                    <div className="card mb-3" style={{backgroundColor: 'var(--primary-color)'}}>
                        <legend id="pet-info" className="mb-3 card-header">Pet Info: </legend>
                        <div className="card-body">
                            <div className="row mb-3">
                                <label htmlFor="name" className="col-sm-2 col-md-form-label">Name:</label>
                                <div className="col-sm-10">
                                    <Field id="name" className="form-control" name="name" />
                                    {errors.name && touched.name ? <div className="text-danger">{errors.name}</div> : null}
                                </div>
                            </div>
                            <div className="row mb-3">
                                <label htmlFor="species" className="col-sm-2 col-md-form-label">Species:</label>
                                <div className="col-sm-10">
                                    <Field id="species" className="form-control" name="species" />
                                </div>

                            </div>
                            <div className="row mb-3">
                                <label htmlFor="breed" className="col-sm-2 col-md-form-label">Breed:</label>
                                <div className="col-sm-10">
                                    <Field id="breed" className="form-control" name="breed" />
                                </div>
                            </div>
                            <div className="row mb-3">
                                <label htmlFor="color" className="col-sm-2 col-md-form-label">Color:</label>
                                <div className="col-sm-10">
                                    <Field id="color" className="form-control" name="color" />
                                </div>
                            </div>
                            <div className="row mb-3">
                                <label htmlFor="ageNum" className="col-sm-2 col-md-form-label">Age:</label>
                                <div className="col-sm-4">
                                    <Field id="ageNum" className="form-control" name="ageNum" type="number" min="1" step="1" />
                                </div>
                                
                                <label id="age-units-options" className="col-md-form-label col-sm-2">Age Units:</label>
                                <div className="col-sm-4">
                                    <div className="form-check form-check-inline">
                                        <label class="form-check-label">
                                            <Field type="radio" className="form-check-input" name="ageUnit" value="Weeks" />
                                            Weeks
                                        </label>
                                    </div>
                                    <div className="form-check form-check-inline">
                                        <label class="form-check-label">
                                            <Field type="radio" className="form-check-input" name="ageUnit" value="Months" />
                                            Months
                                        </label>
                                    </div>
                                    <div className="form-check form-check-inline">
                                        <label class="form-check-label">
                                            <Field type="radio" className="form-check-input" name="ageUnit" value="Years" />
                                            Years
                                        </label>
                                    </div>                                   
                                </div>
                            </div>
                            <fieldset className="row mb-3 form-group">
                                <label id="gen-sex-options" className="col-md-form-label col-sm-2">Sex:</label>
                                <div className="col-sm-4">
                                    <div className="form-check form-check-inline">
                                        <label class="form-check-label">
                                            <Field type="radio" className="form-check-input" name="sexGen" value="Male" />
                                            Male
                                        </label>
                                    </div>
                                    <div className="form-check form-check-inline">
                                        <label class="form-check-label">
                                            <Field type="radio" className="form-check-input" name="sexGen" value="Female" />
                                            Female
                                        </label>
                                    </div>
                                    <div className="form-check form-check-inline">
                                        <label class="form-check-label">
                                            <Field type="radio" className="form-check-input" name="sexGen" value="Unknown" />
                                            Unknown
                                        </label>
                                    </div>
                                </div>
                            
                                <label id="alt-sex-options" className="col-md-form-label col-sm-2">Spayed/Neutered?</label>
                                <div className="col-sm-4">
                                    <div className="form-check form-check-inline">
                                        <label class="form-check-label">
                                            <Field type="radio" className="form-check-input" name="sexAlt" value="Yes" />
                                            Yes
                                        </label>
                                    </div>
                                    <div className="form-check form-check-inline">
                                        <label class="form-check-label">
                                            <Field type="radio" className="form-check-input" name="sexAlt" value="No" />
                                            No
                                        </label>
                                    </div>
                                    <div className="form-check form-check-inline">
                                        <label class="form-check-label">
                                            <Field type="radio" className="form-check-input" name="sexAlt" value="Unknown" />
                                            Unknown
                                        </label>
                                    </div>
                                </div>
                            </fieldset>
                            <fieldset className="row mb-3 form-group">
                                <label id="dog-friendly-options" className="col-md-form-label col-sm-6">Friendly with Most Dogs?</label>
                                <div className="col-sm-6">
                                    <div className="form-check form-check-inline">
                                        <label class="form-check-label">
                                            <Field type="radio" className="form-check-input" name="dogFriendly" value="Yes" />
                                            Yes
                                        </label>
                                    </div>
                                    <div className="form-check form-check-inline">
                                        <label class="form-check-label">
                                            <Field type="radio" className="form-check-input" name="dogFriendly" value="No" />
                                            No
                                        </label>
                                    </div>
                                    <div className="form-check form-check-inline">
                                        <label class="form-check-label">
                                            <Field type="radio" className="form-check-input" name="dogFriendly" value="Unknown" />
                                            Unknown
                                        </label>
                                    </div>
                                </div>
                            </fieldset>
                            <fieldset className="row mb-3 form-group">
                                <label id="cat-friendly-options" className="col-md-form-label col-sm-6">Friendly with Most Cats?</label>
                                <div className="col-sm-6">
                                    <div className="form-check form-check-inline">
                                        <label class="form-check-label">
                                            <Field type="radio" className="form-check-input" name="catFriendly" value="Yes" />
                                            Yes
                                        </label>
                                    </div>
                                    <div className="form-check form-check-inline">
                                        <label class="form-check-label">
                                            <Field type="radio" className="form-check-input" name="catFriendly" value="No" />
                                            No
                                        </label>
                                    </div>
                                    <div className="form-check form-check-inline">
                                        <label class="form-check-label">
                                            <Field type="radio" className="form-check-input" name="catFriendly" value="Unknown" />
                                            Unknown
                                        </label>
                                    </div>
                                </div>
                            </fieldset>
                            <fieldset className="row mb-3 form-group">
                                <label id="kid-friendly-options" className="col-md-form-label col-sm-6">Friendly with Most Kids?</label>
                                <div className="col-sm-6">
                                    <div className="form-check form-check-inline">
                                        <label class="form-check-label">
                                            <Field type="radio" className="form-check-input" name="kidFriendly" value="Yes" />
                                            Yes
                                        </label>
                                    </div>
                                    <div className="form-check form-check-inline">
                                        <label class="form-check-label">
                                            <Field type="radio" className="form-check-input" name="kidFriendly" value="No" />
                                            No
                                        </label>
                                    </div>
                                    <div className="form-check form-check-inline">
                                        <label class="form-check-label">
                                            <Field type="radio" className="form-check-input" name="kidFriendly" value="Unknown" />
                                            Unknown
                                        </label>
                                    </div>
                                </div>
                            </fieldset>
                        </div>
                    </div>

                    <div className="card mb-3" style={{backgroundColor: 'var(--primary-color)'}}>
                        <legend id="rescue-info" className="mb-3 card-header">Rescue Info: </legend>
                        <div className="card-body">
                            <div className="row mb-3">
                                <label htmlFor="petRescue" className="col-sm-2 col-md-form-label">Rescue:</label>
                                <div className="col-sm-10">
                                    <Field id="petRescue" className="form-control" name="petRescue" />
                                    {errors.petRescue && touched.petRescue ? <div className="text-danger">{errors.petRescue}</div> : null}
                                </div>
                            </div>

                            <div className="row mb-3">
                                <label htmlFor="rescueEmail" className="col-sm-2 col-md-form-label">Email:</label>
                                <div className="col-sm-10">
                                    <Field id="rescueEmail" className="form-control" name="rescueEmail" />
                                    {errors.rescueEmail && touched.rescueEmail ? <div className="text-danger">{errors.rescueEmail}</div> : null}
                                </div>
                            </div>
                            <div className="row mb-3">
                                <label htmlFor="rescueCity" className="col-sm-1 col-md-form-label">City:</label>
                                <div className="col-sm-5">
                                    <Field id="rescueCity" className="form-control" name="rescueCity" />
                                    {errors.rescueCity && touched.rescueCity ? <div className="text-danger">{errors.rescueCity}</div> : null}
                                </div>
                                <label htmlFor="rescueState" className="col-sm-2 col-md-form-label">State:</label>
                                <div className="col-sm-4">
                                    <Field as="select" className="form-select" id="rescueState" name="rescueState">
                                        <option value="" label="Select State" />
                                        {states.map((state) => (
                                            <option key={state} value={state}>
                                                {state}
                                            </option>
                                        ))}
                                    </Field>
                                {errors.rescueState && touched.rescueState ? <div className="text-danger">{errors.rescueState}</div> : null}
                                </div>
                            </div>

                        </div>
                    </div>        
                            <div className="row mt-3 mb-3">
                                <label htmlFor="arrivalDate" className="col-sm-8 col-md-form-label">Expected Date of Arrival: </label>
                                <div className="col-sm-4">
                                    <Field id="arrivalDate" className="form-control" name="arrivalDate" type="date" />
                                    {errors.arrivalDate && touched.arrivalDate ? <div className="text-danger">{errors.arrivalDate}</div> : null}
                                </div>
                            </div>
                    <div className="row mb-3 form-group">
                        <label htmlFor="petImage" className="col-sm-8 col-md-form-label">Upload Pet Image: </label>
                        <div className="col-sm-4">
                            <label htmlFor="petImage" className="custom-file-button">Choose file</label>
                            <input
                                type="file"
                                filename="petImage"
                                accept="image/*"
                                className="custom-file-input"
                                id="petImage"
                                onChange={handleFileChange}
                            />
                        </div>
                        {petImage && <p>Selected file: {petImage.name}</p>}
                    </div>

                    <div className="d-grid gap-2">
                        <button className="btn btn-primary btn-lg" type="submit" disabled={isSubmitting}>Submit</button>
                    </div>
                    <div className="form-text">Location will be verified using resources from https://simplemaps.com/data/us-cities.</div>
                </Form>
            )}
        </Formik>
        </div>
        </>
    );
};

export default AddPetPage;